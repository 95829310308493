export const Configs = {
    BASE_API: process.env.REACT_APP_BASE_API_URL,
    DOMAIN: '',

    HOMEPAGE_ROUTE: '/upload-cert',

    CURRENT_PAGE: 1,
    FILE_MAXIMUM: 5, //MB
    PAGE_SIZE_20: 20,
    PAGE_SIZE_4: 4,
};

export const REQUEST_STATE = {
    ERROR: 'ERROR',
    REQUEST: 'REQUEST',
    SUCCESS: 'SUCCESS',
};

// key store in localStorage, Cookies, Session
export const I18LANGUAGE_KEY = 'i18nextLng';
export const TOKEN_KEY = 'authencation';
export const CONFIG_COLUMNS = 'configColumns';

export const ACTION_TYPE = {
    CREATE: 'CREATE',
    LIST: 'LIST',
    VIEW: 'VIEW',
    DELETE: 'DELETE',
    UPDATE: 'UPDATE',
    UNMOUNT: 'UNMOUNT',
};

export const VALID_IMAGE_TYPES = ['image/gif', 'image/jpeg', 'image/png'];

export const STUDENT_CEFT_STATUS = {
    DATA_UPLOAD: '0 - DATA_UPLOAD',
    PDF_GENERATED: '1 - PDF_GENERATED',
    PENDING: '2 - PENDING',
    NEED_SIGN: '3 - NEED_SIGN',
    ERROR_SIGN: '4 - ERROR_SIGN',
    COMPLETE_SIGN: '4 - COMPLETE_SIGN',
    WRAP: '5 - WRAP',
    UPLOAD_TO_BC: '6 - UPLOAD_TO_BC',
    REVOKE: '7 - REVOKE',
    ERROR_SOME_FILE_SEND_EMAIL: '7 - ERROR_SOME_FILE_SEND_EMAIL',
    UPLOAD_JSON_CERT: '8 - UPLOAD_JSON_CERT',
    SEND_EMAIL: '9 - SEND_EMAIL',
    SEND_EMAIL_OLD_KEY: '8 - SEND_EMAIL', // TODO: do dữ liệu cũ, code cập nhật status nên bị lỗi. Tạm thời vá bug như này.
    SEND_EMAIL_FAIL: '10 - EMAIL_FAILED',
};

// 1. UI flow: add router
export const CERT_STATUS = [
    {
        text: 'Chưa ký xong',
        value: [STUDENT_CEFT_STATUS.NEED_SIGN, STUDENT_CEFT_STATUS.PENDING],
    },
    {
        text: 'Đã ký xong',
        value: STUDENT_CEFT_STATUS.COMPLETE_SIGN,
    },
    {
        text: 'Đã tải lên blockchain',
        value: STUDENT_CEFT_STATUS.UPLOAD_TO_BC,
    },
    {
        text: 'Đã gửi email',
        value: STUDENT_CEFT_STATUS.SEND_EMAIL,
    },
];

export const USER_ROLES = {
    SIGNER: 'signer',
    PUBLISHER: 'publisher',
    UPLOADER: 'uploader',
    LEARNER: 'learner',
};

export const NOTIFICATION_TYPES = {
    SIGNER: 'signer',
    PUBLISHER: 'publisher',
    UPLOADER: 'uploader',
};

export const ADDRESS_OWNER_MANAGEMENT = '0xfBb4F8AFa108B4A3B1AD2C80aD77045611f5be86';
