import { Col, Row } from 'antd';
import classNames from 'classnames/bind';
import styles from './AuthLayout.module.sass';
import LogoB4e from 'assets/images/login/logob4eIssuer.png';
import b4eBackground from 'assets/images/login/b4ebackground.png';
let cx = classNames.bind(styles);

function AuthLayout({ children }) {
    return (
        <Row
            className={cx('login__box')}
            style={{
                zIndex: 99,
            }}
        >
            <Col span={8}>
                <div className={cx('auth__form', '--welcome')}>
                    <img src={b4eBackground} className={cx('login-background')}></img>
                    <div className={cx('flex-column', 'welcome-wrapper')}>
                        <img src={LogoB4e} className={cx('auth__welcome-img')}></img>
                        <div className={cx('auth__welcome')}>
                            <span
                                style={{
                                    marginRight: '6px',
                                }}
                            >
                                Chào mừng bạn đến với B4E Issuer
                            </span>
                        </div>
                        <div className={cx('auth__welcome-desc')}>
                            Hệ thống quản lý văn bằng, chứng chỉ, chứng nhận áp dụng công nghệ ký số và blockchain cho
                            các cơ sở giáo dục
                        </div>
                    </div>
                </div>
            </Col>
            <Col span={16}>{children}</Col>
        </Row>
    );
}

export default AuthLayout;
