import { BellOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Menu, Tooltip } from 'antd';
import { CONFIG_COLUMNS, TOKEN_KEY, USER_ROLES } from 'app-configs';
import oraiLogo from 'assets/images/logo/logo-orai.png';
import classNames from 'classnames/bind';
import useOutsideComponent from 'hooks/click-outside';
import Cookies from 'js-cookie';
import { useEffect, useRef, useState } from 'react';
import { Grid } from 'react-flexbox-grid';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { FILTER_NOTIFICATION, GET_NOTIFICATION_LIST, IS_READ_NOTIFICATION } from 'redux/actions/notification';
import styles from './Navigation.sass';
let cx = classNames.bind(styles);

const NOTIFICATION_FILTER_TABS = {
    ALL: 'ALL',
    UNREAD: 'UNREAD',
};
const Navigation = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const userProfile = useSelector((state) => state.user?.profile);
    const listNotify = useSelector((state) => state?.notification?.list);
    const listUnReadNotify = useSelector((state) => state?.notification?.filterNotify);

    const [currentFilterTab, setCurrentFilterTab] = useState(NOTIFICATION_FILTER_TABS.ALL);
    // const [readNotification, setReadNotification] = useState();
    //set visible block notify
    const [isShow, setIsShow] = useState(false);
    const wrapperRef = useRef(null);
    const showState = useRef(null);
    useOutsideComponent(wrapperRef, function () {
        if (showState.current) {
            setIsShow(false);
        }
    });

    //---------------------------
    function handleLogout() {
        Cookies.remove(TOKEN_KEY);
        (JSON.parse(localStorage.getItem('LIST_SAVED_CERTS_ID')) ?? []).forEach((certId) => {
            localStorage.removeItem(certId);
        });
        localStorage.removeItem('LIST_SAVED_CERTS_ID');
        localStorage.removeItem(CONFIG_COLUMNS);

        window.location.reload(false);
    }

    //lỗi do truyền dữ liệu nhưng function chưa khái báo: function readNotification(){}
    function readNotification(notificationItemId) {
        dispatch(IS_READ_NOTIFICATION({ notificationId: notificationItemId }));
    }

    useEffect(() => {
        if (
            userProfile?.roles?.includes(USER_ROLES.SIGNER) ||
            userProfile?.roles?.includes(USER_ROLES.PUBLISHER) ||
            userProfile?.roles?.includes(USER_ROLES.UPLOADER)
        ) {
            if (currentFilterTab === NOTIFICATION_FILTER_TABS.ALL) {
                dispatch(GET_NOTIFICATION_LIST());
            }
            if (currentFilterTab === NOTIFICATION_FILTER_TABS.UNREAD) {
                dispatch(
                    FILTER_NOTIFICATION({
                        isRead: false,
                    }),
                );
            }
        }
    }, [currentFilterTab]);
    return (
        <Grid>
            {userProfile != null ? (
                <div className={cx('header')}>
                    <img src={oraiLogo} alt="orai-logo" className={cx('header__logo')} />
                    <div className={cx('navigation__wraper', 'is-flex', 'al-center')}>
                        {userProfile?.roles?.includes(USER_ROLES.LEARNER) && (
                            <Link
                                to="/student/list-cert"
                                className={cx(
                                    'navigation__item',
                                    'navigation__sign',
                                    `${
                                        history.location.pathname.includes('/student/list-cert') &&
                                        'navigation__item--active'
                                    }`,
                                )}
                            >
                                Danh sách chứng nhận
                            </Link>
                        )}
                        {userProfile?.roles?.includes(USER_ROLES.UPLOADER) && (
                            <Link
                                to="/select-cert"
                                className={cx(
                                    'navigation__item',
                                    'navigation__sign',
                                    `${
                                        history.location.pathname.includes('/upload-cert') && 'navigation__item--active'
                                    }`,
                                )}
                            >
                                Tải tài liệu
                            </Link>
                        )}
                        {(userProfile?.roles?.includes(USER_ROLES.SIGNER) ||
                            userProfile?.roles?.includes(USER_ROLES.PUBLISHER) ||
                            userProfile?.roles?.includes(USER_ROLES.UPLOADER)) && (
                            <Link
                                to="/sign-cert?page=1"
                                className={cx(
                                    'navigation__item',
                                    'navigation__sign',
                                    `${history.location.pathname.includes('/sign-cert') && 'navigation__item--active'}`,
                                )}
                            >
                                Danh sách chứng nhận
                            </Link>
                        )}
                    </div>

                    <div style={{ display: 'flex' }}>
                        {(userProfile?.roles?.includes(USER_ROLES.SIGNER) ||
                            userProfile?.roles?.includes(USER_ROLES.PUBLISHER) ||
                            userProfile?.roles?.includes(USER_ROLES.UPLOADER)) && (
                            <div className={cx('icon-notify')} ref={wrapperRef}>
                                <div
                                    onClick={() => {
                                        showState.current = !isShow;
                                        setIsShow(!isShow);
                                    }}
                                >
                                    <Tooltip placement="bottomLeft" title="Thông Báo">
                                        <BellOutlined className={cx('icon-antd-bell')} />
                                    </Tooltip>

                                    <span
                                        className={cx(listUnReadNotify.data.length > 0 ? 'unread-notification' : '')}
                                    ></span>
                                </div>

                                <div className={cx('notify-modal')} style={{ display: isShow ? 'block' : 'none' }}>
                                    <header className={cx('notify-header')}>
                                        <h3>Thông báo</h3>
                                    </header>
                                    <div className={cx('nav-notify')}>
                                        <button
                                            className={cx(
                                                'btn-nav-notify',
                                                `${currentFilterTab === NOTIFICATION_FILTER_TABS.ALL ? 'active' : ''}`,
                                            )}
                                            onClick={() => {
                                                setCurrentFilterTab(NOTIFICATION_FILTER_TABS.ALL);
                                            }}
                                        >
                                            Tất cả
                                        </button>
                                        <button
                                            className={cx(
                                                'btn-nav-notify',
                                                `${
                                                    currentFilterTab === NOTIFICATION_FILTER_TABS.UNREAD ? 'active' : ''
                                                }`,
                                            )}
                                            onClick={() => {
                                                setCurrentFilterTab(NOTIFICATION_FILTER_TABS.UNREAD);
                                            }}
                                        >
                                            Chưa đọc
                                        </button>
                                    </div>
                                    <ul className={cx('notify-list')}>
                                        {/* khi map thi mang dang rong nen k tra ra du lieu>> day so sanh length len tren */}
                                        {(listUnReadNotify.data.length === 0 &&
                                            currentFilterTab === NOTIFICATION_FILTER_TABS.UNREAD) ||
                                        (listNotify.data.length === 0 &&
                                            currentFilterTab === NOTIFICATION_FILTER_TABS.ALL) ? (
                                            <div className={cx('none-notify')}>Chưa có thông báo mới</div>
                                        ) : (
                                            <>
                                                {(currentFilterTab === NOTIFICATION_FILTER_TABS.ALL
                                                    ? listNotify.data ?? []
                                                    : listUnReadNotify.data ?? []
                                                ).map((notificationItem, index) => {
                                                    return (
                                                        <li
                                                            className={cx(' notify-item', 'notify-item--viewed')}
                                                            key={notificationItem._id}
                                                        >
                                                            <div className={cx('wrapper-notify')}>
                                                                <a
                                                                    className={cx('notify-content')}
                                                                    onClick={() => {
                                                                        //check điều kiện, nếu isRead='false' mới action được
                                                                        if (!notificationItem?.isRead) {
                                                                            readNotification(notificationItem._id);
                                                                        }
                                                                        //chuyển đến chi tiết cert dựa vào id
                                                                        history.push(
                                                                            `/sign-cert/detail/${notificationItem?.batch?._id}`,
                                                                        );
                                                                        setIsShow(false);
                                                                    }}
                                                                >
                                                                    <div className={cx('notify-list-img')}>
                                                                        <img
                                                                            className={cx('avt-issuer')}
                                                                            src="https://picsum.photos/200"
                                                                        ></img>
                                                                    </div>

                                                                    <div className={cx('notify-info')}>
                                                                        <div className={cx('notify-title')}>
                                                                            <div className={cx('notify-route')}>
                                                                                Cần&nbsp;
                                                                                {notificationItem?.notiType === 'sign'
                                                                                    ? 'ký'
                                                                                    : notificationItem?.notiType ===
                                                                                      'upload'
                                                                                    ? 'tải lên '
                                                                                    : notificationItem?.notiType ===
                                                                                      'publish'
                                                                                    ? 'tải lên Blockchain'
                                                                                    : 'gửi email'}
                                                                            </div>
                                                                            <div className={cx('isRead-time')}>
                                                                                <span className={cx('notify-time')}>
                                                                                    <Moment
                                                                                        fromNow
                                                                                        date={
                                                                                            new Date(
                                                                                                notificationItem?.updatedAt,
                                                                                            )
                                                                                        }
                                                                                    ></Moment>
                                                                                </span>
                                                                                <div
                                                                                    className={cx(
                                                                                        notificationItem.isRead
                                                                                            ? ''
                                                                                            : 'unread-notification-item',
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <span className={cx('notification-item')}>
                                                                                <div className={cx('notify-name')}>
                                                                                    {notificationItem?.issuer?._id ===
                                                                                    userProfile?._id
                                                                                        ? 'Bạn'
                                                                                        : notificationItem?.issuer.name}
                                                                                </div>
                                                                                &nbsp;có đợt tải&nbsp;
                                                                                <div className={cx('notify-text')}>
                                                                                    {notificationItem?.batch?.title}
                                                                                </div>
                                                                                &nbsp;
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </li>
                                                    );
                                                })}
                                            </>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        )}

                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item key="edit-info">
                                        <Link to={`/update-info?tabKey=updateInfo`}>Sửa thông tin</Link>
                                    </Menu.Item>
                                    <Menu.Item key="logout" onClick={() => handleLogout()}>
                                        Đăng xuất
                                    </Menu.Item>
                                </Menu>
                            }
                            placement="bottomRight"
                            arrow
                            trigger={['click']}
                        >
                            <div className={cx('header__user')}>
                                <div className={cx('header__user-avatar')}>
                                    <Avatar
                                        src={userProfile?.avatarFileName}
                                        style={{
                                            backgroundColor: '#f56a00',
                                        }}
                                    >
                                        {userProfile?.name?.at(0)}
                                    </Avatar>
                                </div>
                                <div className={cx('header__user-text', 'text_over_flow_1')}>{userProfile?.name}</div>
                                <div className={cx('header__user-icon')}>
                                    <CaretDownOutlined />
                                </div>
                            </div>
                        </Dropdown>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </Grid>
    );
};

export default Navigation;
